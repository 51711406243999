const PULSE_TRACKER_EVENTS = "pulseTrackerEvents"; // The property the Pulse script sends events to
const PULSE_EVENT_LISTENER_LOADED = "pulseEventListenerLoaded";

(function () {
  if (window[PULSE_EVENT_LISTENER_LOADED]) {
    console.debug("Pulse event listener already loaded");
    return;
  }

  window[PULSE_EVENT_LISTENER_LOADED] = true;

  if (!window[PULSE_TRACKER_EVENTS]) {
    try {
      // If not yet set by Pulse script, define the property and add event listener when set
      Object.defineProperty(window, PULSE_TRACKER_EVENTS, {
        configurable: true,
        enumerable: true,
        set(value) {
          if (value && typeof value.addEventListener === "function") {
            value.addEventListener(addGTMListener);
          }

          // Ensures the addEventlistener only runs once by removing the setter
          Object.defineProperty(this, PULSE_TRACKER_EVENTS, {
            value,
            writable: true,
            configurable: true,
            enumerable: true,
          });
        },
      });
      console.debug(
        "window.pulseTrackerEvents was not defined. Defining property",
      );
    } catch (error) {
      throw new Error("Failed to define pulseTrackerEvents property", error);
    }
  } else {
    window.pulseTrackerEvents.addEventListener(addGTMListener);
    console.debug(
      "window.pulseTrackerEvents was already defined. Adding event listener",
    );
  }
})();

function addGTMListener(trackerId, eventType, data) {
  const blockListNames = ["Recommendation inscreen"];

  if (!data.event) return;

  if (data.event.name && blockListNames.includes(data.event.name)) return;

  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: "pulseEventRequest",
    url: location.href,
    data: data,
  });
}
